import React, { useEffect, useState } from "react"
import MainLayout from "../../components/layouts/main-layout.js"
import Seo from "../../components/seo"
import { graphql, Link } from "gatsby"
// import PeakSuiteBanner from "../../images/new-images/peaksuite-banner.jpg"

import Hero from "../../components/hero/banner-peaksuite.js"
import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"

import Banner from "../../components/hero/banner-hometeam.js"

import PartnerSwiperSection from "../../components/peaksuite-sections/PartnerSwiperSection.js"
import PeaksuiteToolsLogos from "../../components/peaksuite-sections/PeaksuiteToolsLogos"
import PeaksuiteToolsDesc from "../../components/peaksuite-sections/PeaksuiteToolsDesc"
import ItConsulting from "../../components/multi-tab/peaksuite-multi-tab"
import Testimonials from "../../components/swiper/testimonial-swiper"
import UnlockEnterprise from "../../components/unlock-enterprise.js"
import Cases from "../../components/cases.js"
import CaseStudySection from "../../components/header/header-casestudy-section"

const CaseComponent = ({ dataSrc }) => {
  return (
    <section id="case-studies-container">
      <div id="case-studies" className="bg-case-study py-5">
        <div className="container d-none">
          <div className="intro text-center">
            <div className="row">
              <div className="col-md-10 mx-auto">
                {/* <p
                  className="h5 text-light-blue text-uppercase font-weight-bold m-0"
                  style={{ fontFamily: "Futura,sans-serif" }}
                >
                  Case Studies
                </p>
                <h2 className=" my-2 text-white">
                  We Save Clients 1000s of Hours. Every Year.
                </h2> */}
                {/* <p className="text-white">
                  We helped a national healthcare provider save over 9,000 hours
                  in weekly labor costs by developing a single application...
                </p> */}
              </div>
            </div>
          </div>
        </div>
        {dataSrc && <Cases allSanityCaseStudy={dataSrc} />}
        <div className="text-center">
          <Link
            className="btn btn-primary btn-arrow mr-2 mt-4 text-center"
            to="/case-studies/"
          >
            More Case Studies
          </Link>
        </div>
      </div>
    </section>
  )
}

const PeaksuiteSections = props => {
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    function f2(ev) {
      ev.preventDefault()
    }

    // A function that invokes a callback when the page has unsaved changes.
    // window.addEventListener("beforeunload", f2)

    // A function that invokes a callback when the page's unsaved changes are resolved.
    // window.removeEventListener("beforeunload", f2)

    // document.body.addEventListener("click", f2, {
    //   capture: false,
    //   passive: true,
    // })
    // document.body.addEventListener("touchstart", f2, {
    //   passive: true,
    //   capture: false,
    // })
  }, [])
  const { data } = props

  return (
    <MainLayout location={props.location} dataSrc={data.allSanityCaseStudy}>
      <Seo
        title="Turnkey Quickbase Solutions | Quandary Consulting Group"
        description="Get more out of Quickbase with a range of proven, out-of-the-box solutions designed for major business use cases—peakSUITE."
      />
      <Hero />
      <PartnerSwiperSection />
      <ItConsulting />
      <Testimonials />
      <CaseStudySection />
      <CaseComponent dataSrc={data.allSanityCaseStudy} />
      <Banner />
      <UnlockEnterprise />
      <section
        className="bg-case-study"
        style={{
          paddingTop: "5rem",
          paddingBottom: "5rem",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mx-auto text-center text-white text-left-mobile">
              <h2 className="text-white">
                peakSUITE Apps: Deploy in Minutes, Save Days of Work
              </h2>
              <Link
                className="speak-to-btnx btn btn-primary btn-arrow mt-4"
                to="/contact/"
              >
                Let's Chat
              </Link>
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  )
}

export default PeaksuiteSections

export const peaksuiteQuery = graphql`
  query peaksuiteQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityCaseStudy(sort: { fields: [publishedAt], order: DESC }, limit: 3) {
      edges {
        node {
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                layout: CONSTRAINED
                width: 580
              )
            }
          }
          bannerImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                layout: CONSTRAINED
                width: 580
              )
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
